@keyframes vitruvianAnimateIn {
  0% {
    opacity: 0;
    width:0;
    translate: 50px 100px;

  }
  100% {
    opacity: 1;
  }
}

@keyframes pop {
  0% {
    transform: scale(0.5);
    opacity: 0.5;
    translate: 0 100px;
  }
  
  100% {
    transform: scale(1);
  }
}